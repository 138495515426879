<template>
  <div class="boxRe">
    <el-row class="dash-board">
      <el-button type="primary" @click="reset" class="ccccc">查询</el-button>
      <el-button  @click="reset" class="wwww">重值</el-button>
      <el-button type="primary" @click="reset" class="d">导word文档</el-button>
      <span style="font-size: 14px" class="qwe">学员:</span>
      <span style="font-size: 14px" class="ewq">班级:</span>
      <el-input placeholder="输入姓名" style="width: 150px" class="wsd"></el-input>
      <el-select style="width: 150px" class="dsw">
        <option></option>
      </el-select>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%; margin-top:10px">
      <el-table-column
        label="姓名"
        align="center"
        width="140">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="班级"
        align="center"
        width="140">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.address }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="主题"
        align="center"
        width="140">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.tel }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="提交时间"
        align="center"
        width="120">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.tel }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="联系电话"
        align="center"
        width="390">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.tel }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="90">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.tel }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import { getAllClass } from '@/api/user.js'
  export default {
    name: 'Cultivate',
    data () {
      return {
        className: [],
        Classname: false,
        stu: {},
        titlee: '创建分组',
        titlees: '查询班级',
        addition: false,
        tableData: [],
        form: {},
        input: '',
        title: '添加用戶',
        dialogFormVisible: false,
        formLabelWidth: '100px',
        datalIst: [{
          class_name: ''
        }],
        dataList: [{
          id: '',
          class_name: '',
          train_number: ''
        }]
      }
    },
    mounted () {
      // 钩子函数
      this.getAllClass()// 初始化所有发布培训班信息
    },
    methods: {
      getAllClass: function () {
        getAllClass(this.className).then(resp => {
          if (resp.data.code === '0') {
            this.dataList = resp.data.data.rows // 接收返回的数据
          }
        })
      }
    }
  }
</script>

<style scoped>
  .dash-board {
    display: flex;
    background-color: white;
    height: 40px;
  }
  .b{
    position: absolute;
    top: 5px;
    left: 540px;
  }
  .c{
    position: absolute;
    left: 70%;
    top: 5px;
  }
  .d{
    position: absolute;
    left: 90%;
    top: 5px;
  }
  .ccccc{
    position: absolute;
    left: 50%;
    top: 3px;
  }
  .wwww{
    position: absolute;
    left: 56%;
    top: 3px;
  }
  .qwe{position: absolute; top: 9px;left: 4px}
  .wsd{position: absolute; left: 40px; top: 3px}
  .ewq{position: absolute; left: 210px;top: 9px}
  .dsw{position: absolute; left: 250px; top: 3px }
  .boxRe{margin-top: -10px}
</style>
